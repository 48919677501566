"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PDAIPrompt = void 0;
const provisions_1 = require("./provisions");
const task = `Extract retirement plan provisions from the plan document below and structure them as a JSON array.`;
const context = `Below there is a retirement plan document. Checked boxes mean the provision is active. The output is intended to be used by people working on the Retirement business (advisors, recordkeepers).`;
const tableStructure = `The output should be a table with the following columns:
* **Category:** The broad category the provision falls under (e.g., "Plan," "Contribution," "Distribution").
* **Provision:** The specific name or title of the provision as it appears in the list of provisions below.
* **Answer:** A concise answer of the provision based on the plan document below. Answer must be 'Not found in the document.' for provisions not found.`;
const outputFormat = `The output should be a JSON array conforming to the following TypeScript type: 
'''typescript:  
export type ProvisionAnswer = {
  category: ProvisionCategory;
  provisionLabel: string;
  modelAnswer: string;
};

Where ProvisionCategory can be one of the following:
"Plan"
"Sponsor/Employer"
"Minimum Age and Service Requirements"
"Contributions"
"Annual Compliance"
"Retirement"
"Vesting and Forfeitures"
"Distribution"
"Loan"

If a provision is not found in the document below, the modelAnswer should be "Not found in the document.".
`;
const targetProvisions = `Please focus on extracting information for the following provisions, using the provided examples as guidance for identification:`;
const additionalInstructions = `* **Prioritize Accuracy:** If a provision is not explicitly mentioned in the document, set modelAnswer to "Not found in the document." rather than providing inaccurate information.
* **Conciseness:** Keep the answers brief and focused on the key points.
* **Plain Language:** Use the same language style from the document and explain complex concepts in simple terms.
* **Consistency:** Strive for consistency in the formatting and style of the answers.
* **Enabled Provisions for the plan:** If a provision's checkbox is unchecked, consider it not applicable to the plan.
* **List answers: ** When the answer is a list of items, make it a bullet list with each item in a new line.
* **Output JSON: ** Ensure output is a valid JSON array with all requested provisions.
`;
class PDAIPrompt {
    static getPrompt() {
        let formattedPrompt = `**Task:** ${task}\n\n\n\n`;
        formattedPrompt += `**Context:** ${context}\n\n\n\n`;
        formattedPrompt += `**Output Definition:** ${tableStructure}\n\n\n\n`;
        formattedPrompt += `**Target Provisions (Examples and Instructions):** ${targetProvisions}\n`;
        for (const provision of provisions_1.PDAIProvisions) {
            formattedPrompt += `\n`;
            formattedPrompt += `* **Category**: ${provision.category}\n`;
            formattedPrompt += `* **Provision**: ${provision.provisionLabel}\n`;
            if (provision.provisionInstructions) {
                formattedPrompt += `* **Instructions**: ${provision.provisionInstructions}\n`;
            }
            for (const example of provision.provisionExamples) {
                formattedPrompt += `* ** ***Example***: ${example}\n`;
            }
            formattedPrompt += `\n\n`;
        }
        formattedPrompt += `\n\n`;
        formattedPrompt += `**Output Format:**: ${outputFormat}}\n\n\n\n`;
        formattedPrompt += `**Additional Instructions:** ${additionalInstructions}\n\n`;
        return formattedPrompt;
    }
    static getPromptForLocations(answers, textAndLocations) {
        return `
    
### Task: Extract location information for each provision's answer within the document provided.

Below there is a list containing provisions and their corresponding answers extracted from a Retirement Plan document ('answers'). 
Also, there is the text content and location data for each text block from the document ('textAndLocations').

**Input:**

1. **provisions:** Contains provision data, including 'category', 'provisionLabel', and 'modelAnswer'.
2. **textAndLocations:** (Array of objects) Each object represents a text block from the document and contains 'TEXT' (the text content) and 'LOCATION' data (page number, top, and bottom coordinates).
    
**Output:**

Return a JSON array with the following structure. 

'''json
[
  {
    "provisionName": "planName", // from answers list
    "provisionLabel": "Plan Name", // from answers list
    "locations": [
    {
      "section": "Section Heading", 
      "pageNumber": 1,
      "top": 0.123,
      "bottom": 0.456
    },
    // ... more locations as needed
  },
  // all provisions from 'answers' list
]
The location is important so the modelAnswer can be easily found in the provided document.

Instructions:

1. Iterate: Process each provision object in the answers list.
2. Comprehend: Understand the meaning and key concepts of the modelAnswer.
3. Match Semantically: Search for text blocks in the textAndLocations data that convey the same meaning as the modelAnswer, even if the wording is not identical.
4. Prioritize Conciseness: Aim to provide the minimum number of locations required to validate the full modelAnswer.
   For simple answers, a single location is usually sufficient.
   For complex answers (e.g., lists, multi-part answers), include multiple locations only if necessary to cover the entire answer.
5. Handle Lists: If the modelAnswer represents a list or multiple answers, identify the location of each list item separately.
6. Extract Location: For each occurrence, extract the pageNumber, top, and bottom coordinates from the LOCATION data.
7. Identify Section: Determine the section heading under which each occurrence appears.
8. Construct Output: For each provision, create an object with the category, provisionLabel, and a locations array. Populate the locations array with objects containing the extracted location data and the identified section.
9. Return JSON Array: Combine all the provision objects into a single JSON array and return it as the output.
10. Output JSON: Ensure output is a valid JSON array with all requested provisions.

Example: (This shows the input and the expected JSON output structure)

Input:

answers:
JSON
[
  {
    "category": "Plan",
    "provisionLabel": "Plan Name",
    "modelAnswer": "COMPANYNAME Non-Union 401(k) Plan" 
  }
]

textAndLocations:
[
  {
    "TEXT": "Plan Data",
    "LOCATION": { "pageNumber": 2, "top": 0.1602, "bottom": 0.1714 } 
  
  },
  {
    "TEXT": "Plan Name: COMPANYNAME Non-Union 401(k) Plan",
    "LOCATION": { "pageNumber": 2, "top": 0.2804, "bottom": 0.2914 } 
  },
  {
    "TEXT": "the COMPANYNAME 401(k) Plan for Non-Union Employees",
    "LOCATION": { "pageNumber": 5, "top": 0.5671, "bottom": 0.5781 } 
  }
]
Output:

JSON
[
  {
    "provisionName": "planName",
    "provisionLabel": "Plan Name",
    "locations": [
      {
        "section": "Plan Data",
        "pageNumber": 2,
        "top": 0.2804,
        "bottom": 0.2914
      }
    ]
  }
]

Important: Ensure the output is a valid JSON array as described above. Do not include any Python code in the response.


Provision List (answers):

${answers}


Document (textAndLocations):

${textAndLocations}
`;
    }
}
exports.PDAIPrompt = PDAIPrompt;
