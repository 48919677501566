import { Plan } from '@helpers/firebase_helper';

export const getStatusDescription = (status: string) => {
  switch (status) {
    case 'uploading':
      return 'Uploading';
    case 'processingText':
      return 'Processing';
    case 'processingAI':
      return 'Processing';
    case 'processingLocationOCR':
    case 'processingLocationAI':
      return 'Initial Review Complete (Advanced Review in Process)';
    case 'inUserReview':
      return 'In Review';
    case 'archived':
      return 'Archived';
    case 'reviewed':
      return 'Reviewed';
    case 'failed':
      return 'Failed';
    default:
      return '';
  }
};

export const areAnswersAvailable = (status: string): boolean => {
  switch (status) {
    case 'uploading':
    case 'processingText':
    case 'processingAI':
    case 'failed':
      return false;
    case 'processingLocationOCR':
    case 'processingLocationAI':
    case 'inUserReview':
    case 'reviewed':
    case 'archived':
      return true;
    default:
      return false;
  }
};

export const areLocationsAvailable = (status: string): boolean => {
  switch (status) {
    case 'uploading':
    case 'processingText':
    case 'processingAI':
    case 'processingLocationOCR':
    case 'processingLocationAI':
    case 'failed':
      return false;
    case 'inUserReview':
    case 'reviewed':
    case 'archived':
      return true;
    default:
      return false;
  }
};

export const hasProcessingAnswersPlans = (plans: Plan[]): boolean => {
  return plans.some(
    (plan: Plan) =>
      plan.status === 'uploading' ||
      plan.status === 'processingText' ||
      plan.status === 'processingAI',
  );
};

export const canSelectPlanCheckbox = (status: string) => {
  return !['processingText', 'processingAI', 'uploading', 'failed'].includes(status);
};

export const canEditPlanName = (status: string) => {
  return !['processingText', 'processingAI', 'uploading', 'failed'].includes(status);
};

export const shouldShowSpinnerStatus = (status: string) => {
  return [
    'processingText',
    'processingAI',
    'processingLocationOCR',
    'processingLocationAI',
    'uploading',
  ].includes(status);
};

export const canDownloadFileOrViewAnswers = (status: string) => {
  return [
    'processingLocationOCR',
    'processingLocationAI',
    'archived',
    'reviewed',
    'inUserReview',
  ].includes(status);
};
