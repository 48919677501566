import { Plan } from '@helpers/firebase_helper';
import { getStatusDescription } from 'pages/PlanList/planHelper';
import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from 'reactstrap';
import Breadcrumbs from '../../Components/Common/Breadcrumb';
import withRouter from '../../Components/Common/withRouter';
import { getFirebaseBackend } from '../../firebase';

const AdminUserActivity = () => {
  document.title = 'Admin - User Activity';

  const [usersActivity, setUsersActivity] = useState<
    {
      user: {
        uid: string;
        email: string;
        displayName: string;
        provider: any;
        metadata: any;
      };
      logins: {
        userId: string;
        email: string;
        timestamp: any;
        metadata: any;
      }[];
      plans: Plan[];
    }[]
  >([]);
  const [selectedUserActivity, setSelectedUserActivity] = useState<{
    user: {
      uid: string;
      email: string;
      displayName: string;
      provider: any;
      metadata: any;
    };
    logins: {
      userId: string;
      email: string;
      timestamp: any;
      metadata: any;
    }[];
    plans: Plan[];
  } | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const firebaseHelper = getFirebaseBackend();

  useEffect(() => {
    const fetchUserActivity = async () => {
      // Fetch all users from Firebase Authentication (replace with your actual logic)
      const allUsersActivity = await firebaseHelper.getUserActivity();
      setUsersActivity(allUsersActivity);
    };

    fetchUserActivity();
  }, []);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  console.log(usersActivity);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs breadcrumbItem='My Plans' />
          {/* User Selection Dropdown */}
          <Card style={{ minHeight: '70vh' }}>
            <Row className='p-3'>
              <Col className='col-8'>
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <div className='d-flex'>
                    <DropdownToggle caret color='primary' className='category-filter'>
                      <Row>
                        <Col className='col-10 text-start'>Select User</Col>
                        <Col className='col-2 text-end'>
                          <i className='mdi mdi-chevron-down px-1' />
                        </Col>
                      </Row>
                    </DropdownToggle>
                    <DropdownMenu>
                      {usersActivity.map((userActivity) => (
                        <DropdownItem
                          key={userActivity.user.uid}
                          onClick={() => setSelectedUserActivity(userActivity)}
                        >
                          {userActivity.user.email} - {userActivity.user.displayName}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </div>
                </Dropdown>
              </Col>
            </Row>
            {selectedUserActivity && (
              <div className='d-flex'>
                <table className='m-3'>
                  <tr>
                    <td style={{ width: '100px' }}>User name:</td>
                    <td className='px-3'>
                      <strong>{' ' + selectedUserActivity?.user.displayName}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Email:</td>
                    <td className='px-3'>
                      <strong>{selectedUserActivity?.user.email}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td># of documents:</td>
                    <td className='px-3'>
                      <strong>
                        {
                          selectedUserActivity?.plans.filter((plan) => plan.status !== 'failed')
                            .length
                        }
                      </strong>
                    </td>
                  </tr>
                </table>
              </div>
            )}

            {/* User Accesses */}
            {selectedUserActivity && selectedUserActivity.logins?.length > 0 && (
              <div style={{ backgroundColor: '#ececec', maxHeight: '200px', overflowY: 'auto' }}>
                {' '}
                {/* Add fixed height and scroll */}
                <Table style={{ position: 'relative' }}>
                  <thead>
                    <tr>
                      <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>
                        Login Date & Time
                      </th>
                      <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>
                        Provider
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedUserActivity.logins.map((access, index) => (
                      <tr key={index}>
                        <td>
                          {new Date(access.timestamp._seconds * 1000).toLocaleDateString('en') +
                            ' ' +
                            new Date(access.timestamp._seconds * 1000).toLocaleTimeString('en')}
                        </td>
                        <td>{access.metadata.additionalUserInfo.providerId}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
            <br></br>
            {/* Uploaded Files */}
            {selectedUserActivity && (
              <div style={{ backgroundColor: '#ececec', maxHeight: '350px', overflowY: 'auto' }}>
                <Table style={{ position: 'relative' }}>
                  <thead>
                    <tr>
                      <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>
                        Document
                      </th>
                      <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>
                        Status
                      </th>
                      <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>
                        Created At
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedUserActivity.plans.map((plan, index) => (
                      <tr key={index}>
                        <td>{plan.documentName || plan.fileName}</td>
                        <td>{getStatusDescription(plan.status) + ` (${plan.status})`}</td>
                        <td>
                          {new Date((plan.createdAt as any)._seconds * 1000).toLocaleDateString(
                            'en',
                          ) +
                            ' ' +
                            new Date((plan.createdAt as any)._seconds * 1000).toLocaleTimeString(
                              'en',
                            )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AdminUserActivity);
