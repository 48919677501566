import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//i18n
import { withTranslation } from 'react-i18next';
// Redux
import { Link } from 'react-router-dom';
import { createSelector } from 'reselect';
import withRouter from '../../Common/withRouter';

// users

import { useSelector } from 'react-redux';

const ProfileMenu = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setUsername] = useState('PDAI User');

  const selectProfileProperties = createSelector(
    (state: any) => state.Profile,
    (profile) => ({
      user: profile.user,
    }),
  );

  const { user } = useSelector(selectProfileProperties);

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem('authUser') || '');
    setUsername(user.username || obj.displayName);
  }, [user]);

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className='d-inline-block'>
        <DropdownToggle className='btn header-item ' id='page-header-user-dropdown' tag='button'>
          <span className='d-none d-xl-inline-block ms-2 me-1'>{username}</span>
          <i className='mdi mdi-chevron-down d-none d-xl-inline-block' />
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-end'>
          <DropdownItem tag='a' href={process.env.PUBLIC_URL + '/profile'}>
            {' '}
            <i className='bx bx-user font-size-16 align-middle me-1' />
            {props.t('My Profile')}{' '}
          </DropdownItem>

          {localStorage.getItem('authUser') &&
            JSON.parse(localStorage.getItem('authUser')!).email.endsWith('@plandataai.com') && (
              <DropdownItem tag='a' href={process.env.PUBLIC_URL + '/usersactivity'}>
                {' '}
                <i className='bx bx-user-plus font-size-16 align-middle me-1' />
                {props.t('Users Activity')}{' '}
              </DropdownItem>
            )}
          <div className='dropdown-divider' />
          <Link to='/logout' className='dropdown-item'>
            <i className='bx bx-power-off font-size-16 align-middle me-1 text-danger' />
            <span>{props.t('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(ProfileMenu));
