import React from 'react';
import { Navigate } from 'react-router-dom';

const AdminProtected = (props) => {
  console.log(localStorage.getItem('authUser'));
  console.log(JSON.parse(localStorage.getItem('authUser')!));
  if (
    !localStorage.getItem('authUser') &&
    JSON.parse(localStorage.getItem('authUser')!).email.endsWith('@plandataai.com')
  ) {
    return <Navigate to={{ pathname: '/login' }} />;
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default AdminProtected;
