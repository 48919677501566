"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PDAIProvisions = exports.CategoryList = exports.ProvisionCategory = void 0;
var ProvisionCategory;
(function (ProvisionCategory) {
    ProvisionCategory["PLAN"] = "Plan";
    ProvisionCategory["EMPLOYER"] = "Sponsor/Employer";
    ProvisionCategory["MIN_AGE_SERVICE_REQS"] = "Minimum Age and Service Requirements";
    ProvisionCategory["CONTRIBUTIONS"] = "Contributions";
    ProvisionCategory["ANNUAL_COMPLIANCE"] = "Annual Compliance";
    ProvisionCategory["RETIREMENT"] = "Retirement";
    ProvisionCategory["VESTING_AND_FORFEITURES"] = "Vesting and Forfeitures";
    ProvisionCategory["DISTRIBUTION"] = "Distribution";
    ProvisionCategory["LOAN"] = "Loan";
})(ProvisionCategory = exports.ProvisionCategory || (exports.ProvisionCategory = {}));
exports.CategoryList = [
    'Plan',
    'Sponsor/Employer',
    'Minimum Age and Service Requirements',
    'Contributions',
    'Annual Compliance',
    'Retirement',
    'Vesting and Forfeitures',
    'Distribution',
    'Loan',
];
exports.PDAIProvisions = [
    {
        category: ProvisionCategory.PLAN,
        provisionLabel: 'Plan Name',
        provisionName: 'planName',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.PLAN,
        provisionLabel: 'Plan Number',
        provisionName: 'planNumber',
        provisionExamples: [],
        provisionInstructions: 'Plan number must be a 3-digit plan. If there is a plan number in a different format, modelAnswer should be "No 3-digit plan number found in document."',
    },
    {
        category: ProvisionCategory.PLAN,
        provisionLabel: 'Plan Type',
        provisionName: 'planType',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.PLAN,
        provisionLabel: 'Plan Year End',
        provisionName: 'planYearEnd',
        provisionInstructions: `Determine the plan's year-end date. Important: This is distinct from the Employer's Fiscal Year. Locate the provision that specifically defines the plan year.`,
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.PLAN,
        provisionLabel: 'Original Effective Date',
        provisionName: 'planInitialEffectiveDate',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.PLAN,
        provisionLabel: 'Restatement Effective Date',
        provisionName: 'planEffectiveDate',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.PLAN,
        provisionLabel: 'Name of Trust/Trustee',
        provisionName: 'planTrustee',
        provisionInstructions: 'The answer should be a specific person or company name, however it can also be a description of how the trustee (person or company) is designated or selected.',
        provisionExamples: [
            'Example 1: [TEXT]: Name and address of Trustee: Company A [modelAnswer]: Company A',
        ],
    },
    {
        category: ProvisionCategory.PLAN,
        provisionLabel: 'Plan Administrator',
        provisionName: 'planAdministrator',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.PLAN,
        provisionLabel: 'Participating (Adopting / Related) Employers',
        provisionName: 'planParticipatingEmployers',
        provisionExamples: [],
        provisionInstructions: 'When available in the document, display Participating Employer name and EIN.',
    },
    {
        category: ProvisionCategory.PLAN,
        provisionLabel: 'Multiple Employer Plan',
        provisionName: 'planMultipleEmployerPlan',
        provisionExamples: [],
        provisionInstructions: `Indicate if the plan is Multiple Employer Plan or if it can become a Multiple Employer Plan in the future.`,
    },
    {
        category: ProvisionCategory.EMPLOYER,
        provisionLabel: 'Employer Name',
        provisionName: 'employerName',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.EMPLOYER,
        provisionLabel: 'EIN',
        provisionName: 'employerEIN',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.EMPLOYER,
        provisionLabel: 'Employer Address',
        provisionName: 'employerAddress',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.EMPLOYER,
        provisionLabel: 'Employer Phone',
        provisionName: 'employerPhone',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.EMPLOYER,
        provisionLabel: 'End of Fiscal Year',
        provisionName: 'employerEndFiscalYear',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.EMPLOYER,
        provisionLabel: 'Type of Entity',
        provisionName: 'employerTypeEntity',
        provisionInstructions: `Do not assume type of entity by employer's name. It's mandatory to have specific language stating the type of entity.`,
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.MIN_AGE_SERVICE_REQS,
        provisionLabel: 'Minimum Age Requirement',
        provisionName: 'minAgeServiceReqsMinimumAgeRequirement',
        provisionExamples: [],
        provisionInstructions: `If the document states there's no age requirement, the answer should be 'No age requirement.' When the provision is not found in the document, the answer should be 'Not found in the document.'`,
    },
    {
        category: ProvisionCategory.MIN_AGE_SERVICE_REQS,
        provisionLabel: 'Minimum Service Requirement',
        provisionName: 'minAgeServiceReqsMinimumServiceRequirement',
        provisionExamples: [],
        provisionInstructions: `There might be different service requirements per contribution type. Summarizes the answer to be clear and concise.`,
    },
    {
        category: ProvisionCategory.MIN_AGE_SERVICE_REQS,
        provisionLabel: 'Entry Date',
        provisionName: 'minAgeServiceReqsEntryDate',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.MIN_AGE_SERVICE_REQS,
        provisionLabel: 'Prospective / Retroactive Entry Date',
        provisionName: 'minAgeServiceReqsProspectiveRetroactiveEntryDate',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.MIN_AGE_SERVICE_REQS,
        provisionLabel: 'Excluded Employees (Classes)',
        provisionName: 'minAgeServiceReqsExcludedEmployeesClasses',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.MIN_AGE_SERVICE_REQS,
        provisionLabel: 'Service Crediting Method - Eligibility',
        provisionName: 'minAgeServiceReqsServiceCreditingMethodEligiblity',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.MIN_AGE_SERVICE_REQS,
        provisionLabel: 'Service Crediting Method - Vesting',
        provisionName: 'minAgeServiceReqsServiceCreditingMethodVesting',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.MIN_AGE_SERVICE_REQS,
        provisionLabel: 'Service Crediting Method - Allocation',
        provisionName: 'minAgeServiceReqsServiceCreditingMethodAllocation',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'Compensation',
        provisionName: 'contributionsCompensation',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'Pre Entry Compensation',
        provisionName: 'contributionsPreEntryCompensation',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'Compensation Exclusions',
        provisionName: 'contributionsCompensationExclusions',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'Post Severance Compensation',
        provisionName: 'contributionsPostSeveranceCompensation',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'Contribution Types',
        provisionName: 'contributionsTypes',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'Elective Deferral Limit',
        provisionName: 'contributionsElectiveDeferralLimit',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'Change / Stop Frequency',
        provisionName: 'contributionsChangeStopFrequency',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'Catch-up Contributions',
        provisionName: 'contributionsCatchup',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'After-Tax Contributions (Voluntary)',
        provisionName: 'contributionsAfterTaxVoluntary',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'Matching Contributions',
        provisionName: 'contributionsMatchingContributions',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'Computation / Calculation Period',
        provisionName: 'contributionsComputationCalculationPeriod',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'Match Catch-Up Contributions',
        provisionName: 'contributionsMatchCatchUp',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'Employer Non-Elective (Profit Sharing) Contributions',
        provisionName: 'contributionsEmployerNonElectiveProfitSharing',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'Non-elective Allocation Formula',
        provisionName: 'contributionsNonElectiveAllocationFormula',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'Safe Harbor (Match)',
        provisionName: 'contributionsSafeHarborMatch',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'Safe Harbor (Non-Elective)',
        provisionName: 'contributionsSafeHarborNonElective)',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'QACA Safe Harbor Contribution',
        provisionName: 'contributionsQACASafeHarbor',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'In-Plan Roth Rollovers',
        provisionName: 'contributionsInPlanRothRollovers',
        provisionInstructions: `Specify whether In-Plan Roth Rollovers are allowed or not.`,
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.CONTRIBUTIONS,
        provisionLabel: 'Rollover Contributions',
        provisionName: 'contributionsRolloverContributions',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.ANNUAL_COMPLIANCE,
        provisionLabel: 'ADP Testing',
        provisionName: 'annualComplicanceADPTesting',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.ANNUAL_COMPLIANCE,
        provisionLabel: 'ACP Testing',
        provisionName: 'annualComplicanceACPTesting',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.ANNUAL_COMPLIANCE,
        provisionLabel: 'Top Paid Group Election',
        provisionName: 'annualComplicanceTopPaidGroupElection',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.ANNUAL_COMPLIANCE,
        provisionLabel: 'Limitation Year',
        provisionName: 'annualComplicanceLimitationYear',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.ANNUAL_COMPLIANCE,
        provisionLabel: 'Top Heavy to Non-key Only (or both key & non-key)',
        provisionName: 'annualComplicanceTopHeavyNonKeyOnly',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.RETIREMENT,
        provisionLabel: 'Normal Retirement Age',
        provisionName: 'retirementNormalRetirementAge',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.RETIREMENT,
        provisionLabel: 'Early Retirement Age',
        provisionName: 'retirementEarlyRetirementAge',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.RETIREMENT,
        provisionLabel: 'Acceleration on Death or Disability',
        provisionName: 'retirementAccelerationDeathDisability',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.VESTING_AND_FORFEITURES,
        provisionLabel: 'Vesting Schedule for Match Contributions',
        provisionName: 'vestingScheduleMatchContributions',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.VESTING_AND_FORFEITURES,
        provisionLabel: 'Vesting Schedule for Nonelective Contributions',
        provisionName: 'vestingScheduleNonelectiveContributions',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.VESTING_AND_FORFEITURES,
        provisionLabel: 'Vesting Schedule (grandfathered/others)',
        provisionName: 'vestingVestingScheduleGrandfatheredOthers)',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.VESTING_AND_FORFEITURES,
        provisionLabel: 'Service Period Credit (Year of Service definition)',
        provisionName: 'vestingServicePeriodCredit',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.VESTING_AND_FORFEITURES,
        provisionLabel: 'Excluded Years of Service',
        provisionName: 'vestingExcludedYearsService',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.VESTING_AND_FORFEITURES,
        provisionLabel: 'Predecessor Service (Elective Service Credit)',
        provisionName: 'vestingPredecessorService',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.VESTING_AND_FORFEITURES,
        provisionLabel: '100% Vesting Events (other than NRA which is required by law)',
        provisionName: 'vestingVestingEvents',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.VESTING_AND_FORFEITURES,
        provisionLabel: 'Forfeiture Allocation Method',
        provisionName: 'vestingForfeitureAllocationMethod',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.DISTRIBUTION,
        provisionLabel: 'Severance Distribution Timing',
        provisionName: 'distributionSeveranceTiming',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.DISTRIBUTION,
        provisionLabel: 'Distribution Options (normal and optional forms)',
        provisionName: 'distributionOptions',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.DISTRIBUTION,
        provisionLabel: 'Mandatory Distribution (RMD: Required Minimum Distributions)',
        provisionName: 'distributionMandatoryDistribution',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.DISTRIBUTION,
        provisionLabel: 'Application of Rollover Balance',
        provisionName: 'distributionApplicationRolloverBalance',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.DISTRIBUTION,
        provisionLabel: 'Auto Rollover',
        provisionName: 'distributionAutoRollover',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.DISTRIBUTION,
        provisionLabel: 'Disability Definition',
        provisionName: 'distributionDisabilityDefinition',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.DISTRIBUTION,
        provisionLabel: 'Hardships',
        provisionName: 'distributionHardships',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.DISTRIBUTION,
        provisionLabel: 'Beneficiary Hardship',
        provisionName: 'distributionBeneficiaryHardship',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.DISTRIBUTION,
        provisionLabel: 'Qualified Reservist',
        provisionName: 'distributionQualifiedReservist',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.DISTRIBUTION,
        provisionLabel: 'Heart Act',
        provisionName: 'distributionHeartAct',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.LOAN,
        provisionLabel: 'Number of Loans',
        provisionName: 'loanNumberLoans',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.LOAN,
        provisionLabel: 'Minimum/Maximum Amount',
        provisionName: 'loanMinMaxAmount',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.LOAN,
        provisionLabel: 'Interest Rate',
        provisionName: 'loanInterestRate',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.LOAN,
        provisionLabel: 'Purpose',
        provisionName: 'loanPurpose',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.LOAN,
        provisionLabel: 'Eligibility',
        provisionName: 'loanEligibility',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.LOAN,
        provisionLabel: 'Cure Period',
        provisionName: 'loanCurePeriod',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.LOAN,
        provisionLabel: 'Pre Payments',
        provisionName: 'loanPrePayments',
        provisionExamples: [],
    },
    {
        category: ProvisionCategory.LOAN,
        provisionLabel: 'Loan Terms',
        provisionName: 'loanTerms',
        provisionExamples: [],
    },
];
